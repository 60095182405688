<template>
  <div>
    <b-form-tags
      :value="value"
      @input="onInput"
    >
      <template
        v-slot="{ inputAttrs, inputHandlers, addTag }"
      >
        <div
          class="d-flex justify-content-between"
        >
          <div
            class="d-flex w-100"
          >
            <input
              id="search-input"
              ref="input"
              v-model="searchText"
              v-bind="inputAttrs"
              v-on="inputHandlers"
              :placeholder="hintText"
              :aria-label="hintText"
              class="w-100 border-0 p-0 input"
              @input="search"
              list="browsers"
            >

          </div>
          <b-button
            v-if="allowAddBeforeSearch"
            variant="link"
            class="text-decoration-none"
            :title="messages.getActionAddToSearch()"
            @click="addTag"
          >
            Add
        </b-button>
          <b-button
            variant="link"
            class="text-decoration-none"
            :title="messages.getActionAddToSearch()"
            @click="addTagSearch"
          >
            <i
              class="icon-magnifier"
            />
        </b-button>
        </div>
      </template>
    </b-form-tags>

    <div class="dropdown-list" v-if="showDropdown" >
      <b-dropdown-item v-for="(suggestion, index) in shownSuggestions" :key="index" @click="selectSuggestion(suggestion)" @hover="selectSuggestion(suggestion)">
        {{ suggestion }}
      </b-dropdown-item>
    </div>
    <div class="pt-3">
      <b-form-tag
        v-for="(tag, key) in mandatoryTags"
        :key="'mandatory-' + key"
        pill
        no-remove
      >
        {{ tag }}
      </b-form-tag>
      <b-form-tag
        v-for="(tag, key) in value"
        :key="key"
        @remove="removeTag(tag,key)"
        pill
        :variant="errorIndexes.includes(key) ? 'danger' : ''"
      >
        {{ tag }}
      </b-form-tag>
    </div>
  </div>
</template>

<script>
import { messages } from '@/utils/strings'

export default {
  name: 'SearchBoxMulti',
  data () {
    return {
      shownSuggestions: this.searchSuggestions,
      showDropdown: false,
      searchText: '',
      messages
    }
  },
  props: {
    hintText: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    },
    mandatoryTags: {
      type: Array,
      default: () => []
    },
    errorIndexes: {
      type: Array,
      default: () => []
    },
    searchSuggestion: {
      type: Array,
      default: () => []
    },
    allowSearch: {
      type: Boolean,
      default: true
    },
    allowAddBeforeSearch: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    addTagSearch (event, value) {
      this.value.push(this.searchText)
      this.onInput(this.value, true)
    },
    handleClickOutside (event) {
      // we are using this instead of @blur because selectSuggestion isn't called when selecting an item
      const input = this.$refs.input
      if (input.contains(event.target)) {
        this.search()
      } else {
        this.showDropdown = false
      }
    },
    search () {
      this.shownSuggestions = this.searchSuggestion.filter((suggestion) => {
        return suggestion.toLowerCase().includes(this.searchText.toLowerCase())
      })
      this.showDropdown = this.shownSuggestions.length > 0
    },
    selectSuggestion (suggestion) {
      this.searchText = suggestion + '='
      this.showDropdown = false
      // Return focus to the input (if possible)
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
    onInput (newValue, performSearch = false) {
      this.$emit('input', newValue, performSearch)
      this.shownSuggestions = []
      this.searchText = ''
      // Return focus to the input (if possible), because the user might have
      // created the tag by clicking the "Add to search" button (and thus lost
      // focus from input).
      this.$nextTick(() => {
        this.$refs?.input?.focus()
      })
    },
    removeTag (removedTag, index) {
      const newValue = this.value.filter(tag => tag !== removedTag)
      this.$emit('input', newValue, false)

      // Return focus to the input (if possible)
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside)
  }
}
</script>

<style scoped lang="scss">
.input:focus {
  outline: none;
}
.icon-magnifier {
  padding: 0 10px 0 10px;
  font-size: 18px;
  color: $blue-400;
}
.badge {
  font-size: initial;
}

.dropdown-list {
  position: absolute;
  width: fit-content;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  z-index: 1000;
}
li::marker {
  content: ""; // workaround for chrome not matching firefox
}
</style>
